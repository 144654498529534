<template>
  <div id="con" class="flex flex-col pdb">
    <div class="img unshrinkalbe">
      <div v-if="!$store.getters.token" class="rut">
        <mt-button class="mtbtn" type="primary" @click="$router.push('/sign')">
          点击登录
        </mt-button>
      </div>
      <img src="@/assets/Home/banner1.png" />
    </div>
    <!-- swipe end-->

    <!-- 单一文章 start -->
    <div class="container flexible rel pdt safeb">
      <van-list class="pdx pdb" v-model="loading" :finished="finished" finished-text="没有更多了" @load="getActivityListPage">
        <div class="articleItem" v-for="(item, index) in list" :key="index" @click="clickActivity(item)">
          <div class="rel">
            <img :src="$store.getters.baseURL + item.coverUrl" class="w100" />
            <div class="tag abs pdx t14" :class="[getStatus(item)]" v-text="status[item.status]"></div>
          </div>
          <div class="pda">
            <div v-text="item.activityName" class="t16"></div>
            <div class="t12 txt-dark mgt-s" v-text="`活动时间 ${activityTimeRangeFactory(item)}`"></div>
          </div>
        </div>
      </van-list>
    </div>
    <LFooter />
  </div>
</template>

<script>
import { Toast } from 'vant'
import { getUserInfo } from '@/api/user'
export default {
  components: {
    LFooter: () => import('@/components/LFooter.vue'),
  },
  data() {
    return {
      loading: false,
      finished: false,
      cnum: 0,
      pages: '', //总页数
      curpages: '', //当前页数
      selected: 'activity',
      hname: '',
      list: [],
      list1: [],
      adminId: '',
      auditStatus: '',
      status: { started: '报名中', finished: '报名已结束', full: '已报满' },
    }
  },
  created() {
    const { state } = this.$route.query
    if (Number(state) === 0) {
      return Toast({
        type: 'fail',
        message: '该账号已禁用',
        onClose: () => {
          location.replace('/')
        },
      })
    }

    if (Number(state) === -1) {
      return Toast({
        type: 'fail',
        message: '该账号已停用',
        onClose: () => {
          location.replace('/')
        },
      })
    }
    this.$store.dispatch('getUserInfo', this.$route.query)
    this.getActivityListPage()
  },

  methods: {
    // 处理时间
    converTimeV(timeString, hours) {
      const time = new Date(timeString)
      const h = parseInt(hours)
      const m = (hours - parseInt(hours)) * 60
      return new Date(time.getFullYear(), time.getMonth(), time.getDate(), time.getHours() - h, time.getMinutes() - m, time.getSeconds())
    },
    activityTimeRangeFactory(activity) {
      const startTime = new Date(activity.activityStartTime.replace(/-/g, '/'))
      const endTime = new Date(activity.activityEndTime.replace(/-/g, '/'))
      const now = new Date()
      let fmt = 'MM-dd hh:mm',
        fmt2 = fmt
      if (
        now.getFullYear() > endTime.getFullYear() ||
        now.getFullYear() < startTime.getFullYear()
      ) {
        fmt = 'yyyy-MM-dd hh:mm'
      }

      if (startTime.getFullYear() != endTime.getFullYear()) {
        fmt2 = 'yyyy-MM-dd hh:mm'
      }

      return startTime.format(fmt) + ' - ' + endTime.format(fmt2)
    },
    getStatus(item) {
      let startTime = new Date(item.activityStartTime.replace(/-/g, '/')),
        now = new Date()
      startTime = this.converTimeV(startTime, item.house)
      if (now > startTime) {
        return (item.status = 'finished')
      }
      if (item.participantsNumber <= item.participantNum) {
        return (item.status = 'full')
      }
      return (item.status = 'started')
    },
    clickActivity(activity) {
      const storageKey = 'selectedActivity'
      // console.log(activity)
      this.setSession(storageKey, activity)
      this.$router.push({
        path: '/ongoing',
        query: { key: storageKey, activityId: activity.activityId },
      })
    },
    //分页查询活动列表
    getActivityListPage() {
      this.loading = true
      this.instance
        .getActivityListPage({
          size: 16,
          current: 1 + this.cnum++,
          parameters: { tweetTitle: '' },
        })
        .then((res) => {
          // console.log(res)
          this.loading = false
          this.finished = res.data.data.current >= res.data.data.pages
          this.list1 = this.handleList(res.data.data.records)
          this.list = this.list.concat(this.list1) //合并对象
          // console.log(this.list1)
        })
    },
    // 处理list
    handleList(list) {
      list.forEach(item => {
        const time = (new Date(item.activityStartTime.replace(/\-/g, '/')).getTime()) - (new Date(item.enrollEndTime.replace(/\-/g, '/')).getTime())
        item['house'] = (time / 3600000).toFixed(1)
      })
      return list
    }
  },
  mounted() {

  },
  watch: {
    // 监听底部选项卡变化
    selected(newval) {
      if (newval == 'activity') {
        this.$router.push('/')
      } else if (newval == 'tweet') {
        this.$router.push('/tweet')
      } else if (newval == 'mine') {
        this.$router.push('/mine')
      }
    },
  },
  computed: {
    loginname() {
      //获取从注册页传递过来的数据
      return this.$route.params.hname
    },
  },
}
</script>

<style scoped>
.tab span {
  position: relative;
  top: 1.375rem;
  left: 0.7rem;
  width: 2.25rem;
  height: 1.5625rem;
  font-size: 1.125rem;
}

.tab a {
  color: #5e5e5e;
}

.tab>>>.mint-tab-item.is-selected {
  background-color: #ffffff;
}

.tab>>>.mint-tabbar {
  background-size: 100% 0.0625rem;
}

.tab img {
  width: 3.125rem;
  height: 3.125rem;
}

.tab {
  height: 5.5rem;
  background-color: #ffffff;
  z-index: 3;
}

.container {
  margin-top: 26.4rem;
  background-color: #f8f9fc;
}

.articleItem {
  border-radius: 2.1rem;
  overflow: hidden;
  background-color: #ffffff;
}

.articleItem+.articleItem {
  margin-top: 2.1rem;
}

.tag {
  right: 0;
  top: 0;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  border-bottom-left-radius: 1.18rem;
}

.tag.finished {
  color: #fc5531;
  background-color: #ffeeeb;
}

.tag.started {
  color: #52cc0c;
  background-color: #eafedf;
}

.tag.full {
  color: #ffbd00;
  background-color: #fff7e1;
}

.art_span {
  width: 16%;
  height: 14%;
  font-size: 1.6rem;
  text-align: center;
  font-weight: bold;
  line-height: 2.7rem;
  margin-top: -0.05rem;
  margin-right: -0.011rem;
  border-radius: 0 1rem 0 1rem;
  margin-left: auto;
  /*子元素在父容器中右对齐*/
}

.rut {
  position: absolute;
  top: 53%;
  left: 41%;
}

.img {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.img img {
  width: 100%;
}

.mtbtn {
  width: 9rem;
  height: 3.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 0.69rem 1.3125rem;
  border-radius: 2rem;
}

.mtbtn a {
  text-decoration: none;
  color: #ffffff;
}

.mtheader img {
  line-height: 0;
}

.mtheader {
  height: 5.6rem;
  font-size: 2rem;
}
</style>
