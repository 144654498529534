import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from './store' 
import Home from '../views/Home.vue'
import Tweet from '../views/Tweet.vue'
import Mine from '../views/Mine.vue'
import Article from '../views/Article.vue'
import Ongoing from '../views/Artivity/Ongoing.vue'
import Sign from '../views/Sign/Sign.vue'
import Register from '../views/Sign/Register.vue'
// 注册个人信息
import AuthenStaff from '../views/Sign/AuthenStaff.vue'
import AuthenSch from '../views/Sign/AuthenSch.vue'
import AuthenGuest from '../views/Sign/AuthenGuest.vue'
import AuthenMom from '../views/Sign/AuthenMom.vue'
//核销页面
import WriteOff from '../views/Sign/WriteOff.vue'
//已核销记录页面
import Record from '../views/Sign/Record.vue'
//扫码核销页面
import ScanCode from '../views/Sign/ScanCode.vue'

// 已登录个人信息
import Alumns from '../views/Mine/Alumns.vue'
import Staff from '../views/Mine/Staff.vue'
import Guest from '../views/Mine/Guest.vue'
import Parent from '../views/Mine/Parent.vue'

//我的活动页面
import Activity from '../views/Mine/Activity.vue'
import Cancel from '../views/Mine/Cancel.vue'
import Pay from '../views/Mine/Pay.vue'
import Writeoffed from '../views/Mine/Writeoffed.vue'
import Writeoffce from '../views/Mine/Writeoffce.vue'
//二维码页面
import Qrcode from '../views/Mine/Qrcode.vue'

// 详情页资料下载
import Datadown from '../views/DownLoad/Datadown.vue'
// 测试页面
import Testhome from '../test/Testhome.vue'
import ConItem from '../test/ConItem.vue'
import Zhuceye from '../test/Zhuceye.vue'
import Log from '../test/Log.vue'
import Loading from '../test/Loading.vue'
import Timer from '../test/Timer.vue'
import Timeout from '../test/Timeout.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/log',
    name: 'Log',
    component: Log
  },
  {
    path: '/timer',
    name: 'Timer',
    component: Timer
  },
  {
    path: '/timeout',
    name: 'Timeout',
    component: Timeout
  },
  {
    path: '/loading',
    name: 'Loading',
    component: Loading
  },
  {
    path: '/zhuceye',
    name: 'Zhuceye',
    component: Zhuceye
  },
  {
    path: '/testhome',
    name: 'Testhome',
    component: Testhome
  },
  {
    path: '/conItem',
    name: 'ConItem',
    component: ConItem
  },
  {
    path: '/sign',
    name: 'Sign',
    component: Sign
  },
  {
    path: '/writeOff',
    name: 'WriteOff',
    component: WriteOff
  },
  {
    path: '/record',
    name: 'Record',
    component: Record,
    meta: {
      title: '已核销记录'
    }
  },
  {
    path: '/scanCode',
    name: 'ScanCode',
    component: ScanCode
  },
  
  {
    path: '/sign/authenStaff',//教职工
    name: 'AuthenStaff',
    component: AuthenStaff
  }, {
    path: '/sign/authenSch',//校友
    name: 'AuthenSch',
    component: AuthenSch
  }, {
    path: '/sign/authenGuest',//嘉宾
    name: 'AuthenGuest',
    component: AuthenGuest
  }, {
    path: '/sign/authenMom',//家长
    name: 'AuthenMom',
    component: AuthenMom
  }, {
    path: '/sign/register',
    name: 'Register',
    component: Register
  },
  {
    // path:'/ongoing',
    path: '/ongoing',
    name: 'Ongoing',
    meta: { title: '活动报名' },
    component: Ongoing
  },
  // 资料下载
  {
    path: '/ongoing/download',
    name: 'Datadown',
    component: Datadown
  },
  {
    path: '/article',
    name: 'Article',
    component: Article,
    meta: { title: '详情' }
  },
  // 我的
  {
    path: '/mine',
    name: 'Mine',
    component: Mine,
    meta: { title: '我的' }
  },
  // 我的点击个人信息跳转四页面
  {
    path: '/mine/alumns',
    name: 'Alumns',
    component: Alumns
  },
  {
    path: '/mine/staff',
    name: 'Staff',
    component: Staff
  },
  {
    path: '/mine/parent',
    name: 'Parent',
    component: Parent
  },
  {
    path: '/mine/guest',
    name: 'Guest',
    component: Guest
  },
  // 我的点击我的活动跳转页面
  {
    path: '/mine/activity',
    name: 'Activity',
    component: Activity
  },
  // 我的点击我的活动跳转四页面
  {
    path: '/mine/cancel',
    name: 'Cancel',
    component: Cancel
  },
  {
    path: '/mine/pay',
    name: 'Pay',
    component: Pay
  },
  {
    path: '/mine/writeoffed',
    name: 'Writeoffed',
    component: Writeoffed
  },
  {
    path: '/mine/writeoffce',
    name: 'Writeoffce',
    component: Writeoffce
  },
  //二维码页面
  {
    path: '/mine/qrcode',
    name: 'Qrcode',
    component: Qrcode
  },
  // 主界面頁面
  {
    path: '/tweet',
    name: 'Tweet',
    component: Tweet,
    meta: {
      title: '推文'
    }
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]




const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {//这个方法返回滚动位置的对象信息
    // return 期望滚动到那个位置
    return { x: 0, y: 0 }
  }
})


router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '林荫'
  next()
})

export default router
