<template>
  <div class="pdx pdt" style="background-color: #f8f9fc;text-align: center;">
    <!-- -->
    <span v-if="this.list.length === 0" style="font-size: 2rem;color:#666">暂无推文</span>
    <van-list v-else :loading="loading" :finished="finished" finished-text="没有更多了" @load="getTweet">

      <div v-for="(item, index) in list" :key="index">
        <p class="txt-center t12 mgb" v-text="item.dateName"></p>
        <div class="articleItem mgb" v-for="(tweet, index) in item.tweets" :key="index" @click="clickTweet(tweet)">
          <img class="w100" :src="$store.getters.baseURL + tweet.previewUrl" alt="" />
          <div>
            <p>{{ tweet.tweetTitle }}</p>
          </div>
        </div>
      </div>
    </van-list>
    <div class="safeb" style="height: 8rem"></div>
    <LFooter />
  </div>
</template>

<script>
export default {
  components: {
    LFooter: () => import("@/components/LFooter.vue"),
  },
  data() {
    return {
      tnum: 0,
      list: [],
      loading: false,
      finished: false,
    };
  },
  created() {
    this.getTweet();
  },
  mounted() {

  },
  methods: {
    clickTweet(tweet) {
      const sessionKey = "selectedTweet";
      this.setSession(sessionKey, tweet);
      this.$router.push({
        path: "/article",
        query: {
          key: sessionKey,
          tweetTitle: tweet.tweetTitle
        },
      });
    },
    dateNameFactory(list = []) {
      const dates = {};
      list.forEach((o) => {
        const date = new Date((o.tweetCreateTime || "").replace(/\-/g, "/")).getDateName("yyyy年MM月dd日");
        dates[date] = dates[date] || [];
        dates[date].push(o);
      });

      this.list = this.list.concat(
        Object.keys(dates).map((date) => ({
          dateName: date,
          tweets: dates[date],
        }))
      );
    },
    getTweet() {
      //发送请求获取数据
      this.loading = true;
      this.instance
        .userTweet({
          size: 10,
          current: 1 + this.tnum++,
          parameters: {},
        })
        .then((res) => {
          const { code, data, msg } = res.data;
          this.loading = false
          if (code === 1001) {
            this.dateNameFactory(data.records);
            this.finished = data.pages === data.current;
          } else {
            this.$toast.fail(msg);
          }
        }).catch(() => {
          // 报错也关闭加载动画
          this.loading = false;

        });
    },

  },
  watch: {
    // 监听底部选项卡变化
    selected(newval) {
      if (newval == "activity") {
        this.$router.push("/");
      } else if (newval == "tweet") {
        console.log("推送");
        this.$router.push("/tweet");
      } else if (newval == "mine") {
        console.log("我的");
        this.$router.push("/mine");
      }
    },
  },
};
</script>

<style scoped>
/* 底部选项卡样式 */
.tab span {
  position: relative;
  top: 1.375rem;
  left: 0.7rem;
  width: 2.25rem;
  height: 1.5625rem;
  font-size: 1.125rem;
}

.tab a {
  color: #5e5e5e;
}

.tab>>>.mint-tab-item.is-selected {
  background-color: #ffffff;
}

.tab>>>.mint-tabbar {
  background-size: 100% 0.0625rem;
}

.tab img {
  width: 3.125rem;
  height: 3.125rem;
}

.tab {
  height: 5.5rem;
  background-color: #ffffff;
  z-index: 3;
}

/* 单一页面样式 */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 0 2.1rem;
}

.articleItem {
  border-radius: 2rem;
  overflow: hidden;
  background-color: #ffffff;
}

.tabletime {
  color: #a0a0a0;
}

.articleJump {
  width: 100%;
  text-decoration: none;
}

.art_img {
  width: 100%;
  margin-top: -1rem;
}

.articleItem div {
  width: 100%;
}

.articleItem div p {
  margin: 0;
  font-size: 2rem;
  padding: 2.1rem;
  color: #1b1b1b;
}

.img {
  z-index: 3;
  position: fixed;
  top: 5.6rem;
  width: 100%;
  height: 26.25rem;
  background-image: url("../assets/Home/banner1.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* 头部样式 */
.img_right {
  width: 3rem;
  line-height: 0;
  margin-left: 75%;
}

.img_left {
  width: 3rem;
  line-height: 0;
}

.mtheader {
  height: 5.5rem;
  font-size: 2.125rem;
}
</style>